import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import _ from 'lodash';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-mortuary-preparation-options',
  templateUrl: './mortuary-preparation-options.component.html',
  styleUrls: ['./mortuary-preparation-options.component.scss']
})
export class MortuaryPreparationOptionsComponent implements OnInit {

  form = new FormGroup({
    preparationServiceProvider: new FormControl(true, []),
    careInstructions: new FormControl(true, []),
    hazardousMaterials: new FormControl(true, []),
    additionalServiceItems: new FormControl(true, []),
    clothingDetails: new FormControl(true, []),
    clothingInstructionsLegacy: new FormControl(true, []),
    specificClothingInstructions: new FormControl(true, []),
    clothingSignatory: new FormControl(true, []),
    jewelleryAtTimeOfDeath: new FormControl(true, []),
    jewelleryInstructionsLegacy: new FormControl(true, []),
    jewelleryProvidedByFamily: new FormControl(true, []),
    jewellerySignatory: new FormControl(true, []),
    transfers: new FormControl(true, []),
    viewings: new FormControl(true, []),
    events: new FormControl(true, []),
  });

  submitted: boolean;

  get careInstructionsFormControl(): FormControl {
    return this.form.controls['careInstructions'] as FormControl;
  }

  get hazardousMaterialsFormControl(): FormControl {
    return this.form.controls['hazardousMaterials'] as FormControl;
  }

  get clothingDetailsFormControl(): FormControl {
    return this.form.controls['clothingDetails'] as FormControl;
  }

  get clothingInstructionsLegacyFormControl(): FormControl {
    return this.form.controls['clothingInstructionsLegacy'] as FormControl;
  }

  get specificClothingInstructionsFormControl(): FormControl {
    return this.form.controls['specificClothingInstructions'] as FormControl;
  }

  get jewelleryAtTimeOfDeathFormControl(): FormControl {
    return this.form.controls['jewelleryAtTimeOfDeath'] as FormControl;
  }

  get jewelleryInstructionsLegacyFormControl(): FormControl {
    return this.form.controls['jewelleryInstructionsLegacy'] as FormControl;
  }

  get jewelleryProvidedByFamilyFormControl(): FormControl {
    return this.form.controls['jewelleryProvidedByFamily'] as FormControl;
  }

  get transfersFormControl(): FormControl {
    return this.form.controls['transfers'] as FormControl;
  }

  get viewingsFormControl(): FormControl {
    return this.form.controls['viewings'] as FormControl;
  }

  get eventsFormControl(): FormControl {
    return this.form.controls['events'] as FormControl;
  }

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    this.submitted = false;

    this.loadCachedOptions();

    const arrangementForm = this.config.data.arrangement.form;

    this.setLegacyOptions(arrangementForm);

    this.form.valueChanges.subscribe((value) => {

      this.storeCachedOptions(value);

    });

  }

  ngOnInit(): void { }

  onSubmit(event: Event): void {

    this.submitted = true;

    if (this.form.invalid) {

      return;

    }

    this.ref.close(this.form.value);

  }

  onClose(event: Event) {
    this.ref.close('cancel');
  }

  private loadCachedOptions() {

    const cachedOptions = localStorage.getItem('mortuary-preparation-options');

    if (cachedOptions) {

      const options = JSON.parse(cachedOptions);

      this.form.patchValue(options);

    }

  }

  private storeCachedOptions(options: any) {

    localStorage.setItem('mortuary-preparation-options', JSON.stringify(options));

  }

  private setLegacyOptions(arrangementForm: FormGroup) {

    const legacyClothingInstructions = arrangementForm.get('preparation.clothing.instructions') as FormArray;
    const legacyJewelleryInstructions = arrangementForm.get('preparation.jewellery.instructions') as FormArray;

    if (legacyClothingInstructions.value.length === 0) {
      this.clothingInstructionsLegacyFormControl.patchValue(false);
    }

    if (legacyJewelleryInstructions.value.length === 0) {
      this.jewelleryInstructionsLegacyFormControl.patchValue(false);
    }

  }

}
