<form [formGroup]="form" (ngSubmit)="onSubmit($event)">

  <div class="flex flex-column">

    <div class="content-container">

      <div class="formgrid grid">

        <div class="col-12 mt-3 mb-3">
          <p-card class="card-info w-full">
            <p class="text-center">You might not always want to print the whole Mortuary Preparation Form. You can select which sections are printed using the checkboxes below.</p>
          </p-card>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="careInstructionsFormControl" [binary]="true" [formControl]="careInstructionsFormControl"></p-checkbox>
          <label for="careInstructionsFormControl">Care Instructions</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="hazardousMaterialsFormControl" [binary]="true" [formControl]="hazardousMaterialsFormControl"></p-checkbox>
          <label for="hazardousMaterialsFormControl">Hazardous Materials</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="clothingDetailsFormControl" [binary]="true" [formControl]="clothingDetailsFormControl"></p-checkbox>
          <label for="clothingDetailsFormControl">Clothing Details</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="clothingInstructionsLegacyFormControl" [binary]="true" [formControl]="clothingInstructionsLegacyFormControl"></p-checkbox>
          <label for="clothingInstructionsLegacyFormControl">Clothing Instructions <span class="opacity-30">Legacy Arrangements</span></label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="specificClothingInstructionsFormControl" [binary]="true" [formControl]="specificClothingInstructionsFormControl"></p-checkbox>
          <label for="specificClothingInstructionsFormControl">Specific Clothing Instructions</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="jewelleryAtTimeOfDeathFormControl" [binary]="true" [formControl]="jewelleryAtTimeOfDeathFormControl"></p-checkbox>
          <label for="jewelleryAtTimeOfDeathFormControl">Jewellery at time of Death</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="jewelleryProvidedByFamilyFormControl" [binary]="true" [formControl]="jewelleryProvidedByFamilyFormControl"></p-checkbox>
          <label for="jewelleryProvidedByFamilyFormControl">Jewellery Provided by Family</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="jewelleryInstructionsLegacyFormControl" [binary]="true" [formControl]="jewelleryInstructionsLegacyFormControl"></p-checkbox>
          <label for="jewelleryInstructionsLegacyFormControl">Jewellery Instructions <span class="opacity-30">Legacy Arrangements</span></label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="transfersFormControl" [binary]="true" [formControl]="transfersFormControl"></p-checkbox>
          <label for="transfersFormControl">List Transfers</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="eventsFormControl" [binary]="true" [formControl]="viewingsFormControl"></p-checkbox>
          <label for="eventsFormControl">List Viewings</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="eventsFormControl" [binary]="true" [formControl]="eventsFormControl"></p-checkbox>
          <label for="eventsFormControl">List Events</label>
        </div>

      </div>

    </div>

    <div class="button-actions mt-3">

      <button pButton pRipple type="button" label="Cancel" (click)="onClose($event)" class="p-button-secondary"></button>
      <button pButton pRipple type="submit" label="Submit"></button>
      
    </div>

  </div>

</form>